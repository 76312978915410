import React, { useEffect } from "react";
import Splash from "./assets/Splash.png";
import Home1 from "./assets/Homepage.png";
import Home2 from "./assets/Homepage1.png";
import On1 from "./assets/Onboarding.png";
import On2 from "./assets/Onboarding1.png";
import On3 from "./assets/Onboarding2.png";
import On4 from "./assets/Onboarding3.png";
import Profile from "./assets/Profile.png";
import GetApp from "./GetApp";
import QuickLinks from "./QuickLinks";
import { useNavigate } from "react-router-dom";
import Welcome from "./Welcome";
import Logo from "../src/assets/logo.png";

const Home = () => {
  // Array of image URLs for demonstration; replace these with your actual image URLs
  const images = [Splash, On1, On2, On3, On4, Home1, Home2, Profile];
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <div
          className="  w-[100%] md:w-[100%] flex justify-start items-end gap-2 cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={Logo} className="w-[50px] h-[50px] rounded-lg" alt="logo" />
          <h1 className="text-3xl font-bold text-white text-left ">
            MYSYNR.COM LLC
          </h1>
        </div>
        {/* <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="max-w-sm rounded overflow-hidden shadow-lg"
            >
              <img className="w-full" src={image} alt={`Gallery ${index}`} />
            </div>
          ))}
        </div> */}
        <div className="mt-20">
          <h1 className="text-4xl md:text-6xl font-bold text-white text-left my-8">
            Syn and Dance your Life Away!
          </h1>
          <p className="text-2xl md:text-3xl  text-white text-left my-8">
            Let’s create a video on your favorite audio file & Rock your own
            video and Use Lip Syncing, Props, Body magic to create magic while
            recording video.
          </p>
        </div>
      </div>

      <div className="my-20">
        <Welcome />
      </div>

      {/* get app */}
      <div className="flex justify-center">
        <GetApp />
      </div>

      {/* quick links */}
      <div className="flex flex-col md:flex-row justify-around items-center">
        <QuickLinks />
        {/* Contact Us Section */}
        <div className="py-8">
          <div className="container mx-auto px-4 flex flex-col md:flex-row justify-around items-center gap-6">
            <h2 className="text-2xl font-bold text-white  text-center mt-8 md:mt-0">
              Contact Us
            </h2>
            <div className="flex justify-center items-center">
              <p
                onClick={() => navigate("/email-us")}
                className="text-xl cursor-pointer text-gray-300 underline hover:text-white"
              >
                Email Us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
