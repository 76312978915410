import React, { useEffect } from "react";
import QuickLinks from "./QuickLinks";
import { useNavigate } from "react-router-dom";
import Logo from "../src/assets/logo.png";

const Terms = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="text-white p-8">
      <div
        className="  w-[100%] md:w-[100%] flex justify-start items-end gap-2 cursor-pointer mb-8"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={Logo} className="w-[50px] h-[50px] rounded-lg" alt="logo" />
        <h1 className="text-3xl font-bold text-white text-left ">
          MYSYNR.COM LLC
        </h1>
      </div>
      <p className="text-lg font-bold">Terms and condition</p>
      <p className="text-sm mb-4">Last updated on 14 May 2024</p>
      <p>
        Welcome to our Mysynr application (the “application”)! Before you start
        creating and sharing amazing content, please read and agree to the
        following terms and conditions carefully:
      </p>
      <p className="text-lg font-bold mt-4">Acceptance of Terms :</p>
      <p className="text-sm">
        The following terms and conditions (the “terms”), together with any
        documents they expressly incorporate by reference, govern your access to
        and use of the application, including any content, functionality, and
        services offered on or through the application. By using our
        application, you accept and agree to abide by these terms. If you do not
        agree with any part of these terms, please do not use our application.
        By using this application, you represent and warrant that you meet all
        of the foregoing eligibility requirements set forth herein and in the
        Mysynr.com LLC (the “Company”) privacy policy
        https://mysynr.com/privacy-policies (the “Privacy Policy”). If you do
        not meet all of these requirements, you must not access or use the
        application.
      </p>
      <p className="text-lg font-bold mt-4">
        Access to the Application and Enforcement :
      </p>
      <p className="text-sm">
        We reserve the right to withdraw or amend this Application, and any
        service or material we provide on the Application, in our sole
        discretion without notice. We will not be liable if for any reason all
        or any part of the Application is unavailable at any time or for any
        period. From time to time, we may restrict user access, including
        registered user access, to some parts of the Application or the entire
        Application.
        <br />
        <br />
        We reserve the right to remove or refuse and take any action deemed
        necessary or appropriate in our sole discretion with respect to the
        content provided on the application or illegal or unauthorized use of
        the application. We have the right to disclose your identity or other
        information about you to any third party who claims that material posted
        by you violates their rights, including their intellectual property
        rights or their right to privacy or to cooperate fully with any law
        enforcement authorities or court order requesting or directing us to
        disclose the identity or other information of anyone posting any content
        or other material on or through the application. YOU WAIVE AND HOLD
        HARMLESS THE COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY
        THE COMPANY DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY
        EITHER THE COMPANY OR LAW ENFORCEMENT AUTHORITIES.
        <br />
        <br />
        You are responsible for making all arrangements necessary for you to
        have access to the Application.
        <br />
        <br />
        To access the Application or some of the resources it offers, you may be
        asked to provide certain registration details or other information. It
        is a condition of your use of the Application that all the information
        you provide on the Application is correct, current, and complete. You
        agree that all information you provide to register with this Application
        or otherwise, including, but not limited to, through the use of any
        interactive features on the Application, is governed by our Privacy
        Policy, and you consent to all actions we take with respect to your
        information consistent with our Privacy Policy.
      </p>

      <p className="text-lg font-bold mt-4">
        Content Creation and Application Use :
      </p>
      <p className="text-sm">
        You are solely responsible for the content, such as any text,
        photographs, user videos and sound recordings, you create, upload, or
        share using our application and you, not the Company, have full
        responsibility for such content, including its legality, reliability,
        accuracy, and appropriateness. Ensure that your content complies with
        applicable laws and regulations, and does not infringe upon the rights
        of others, including copyrights and trademarks. Your content must not:
      </p>
      <ul className="list-disc list-inside text-sm">
        <li className="my-2">
          Contain any material that is defamatory, obscene, indecent, abusive,
          offensive, harassing, violent, hateful, inflammatory, or otherwise
          objectionable.
        </li>
        <li className="my-2">
          Promote sexually explicit or pornographic material, violence, or
          discrimination based on race, sex, religion, nationality, disability,
          sexual orientation, or age.
        </li>
        <li className="my-2">
          Infringe any patent, trademark, trade secret, copyright, or other
          intellectual property or other rights of any other person.
        </li>
        <li className="my-2">
          Violate the legal rights (including the rights of publicity and
          privacy) of others or contain any material that could give rise to any
          civil or criminal liability under applicable laws or regulations or
          that otherwise may be in conflict with these terms and our Privacy
          Policy.
        </li>
        <li className="my-2">Be likely to deceive any person.</li>
        <li className="my-2">
          Promote any illegal activity, or advocate, promote, or assist any
          unlawful act.
        </li>
        <li className="my-2">
          Cause annoyance, inconvenience, or needless anxiety or be likely to
          upset, embarrass, alarm, or annoy any other person.
        </li>
        <li className="my-2">
          Impersonate any person or misrepresent your identity or affiliation
          with any person or organization.
        </li>
        <li className="my-2">
          Involve commercial activities or sales, such as contests, sweepstakes,
          and other sales promotions, barter, or advertising.
        </li>
        <li className="my-2">
          Give the impression that they emanate from or are endorsed by us or
          any other person or entity, if this is not the case.
        </li>
      </ul>
      <p className="text-lg font-bold mt-4">
        You represent and warrant that: :
      </p>
      <ul className="list-disc list-inside text-sm">
        <li className="my-2">
          You own or control all rights in and to the or are licensed to
          contribute the content.
        </li>
        <li className="my-2">
          All of your content does and will comply with these terms.
        </li>
      </ul>
      <p className="text-lg font-bold mt-4">
        You may use the Application only for lawful purposes and in accordance
        with these terms. You agree not to use the Application :
      </p>
      <ul className="list-disc list-inside text-sm">
        <li className="my-2">
          In any way that violates any applicable federal, state, local, or
          international law or regulation (including, without limitation, any
          laws regarding the export of data or software to and from the US or
          other countries).
        </li>
        <li className="my-2">
          For the purpose of exploiting, harming, or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information, or otherwise.
        </li>
        <li className="my-2">
          To impersonate or attempt to impersonate the Company, a Company
          employee, another user, or any other person or entity (including,
          without limitation, by using email addresses associated with any of
          the foregoing).
        </li>
        <li className="my-2">
          To engage in any other conduct that restricts or inhibits anyone&#39;s
          use or enjoyment of the Application, or which, as determined by us,
          may harm the Company or users of the Application, or expose them to
          liability.
        </li>
      </ul>
      <p className="text-lg font-bold mt-4">Additionally, you agree not to :</p>
      <ul className="list-disc list-inside text-sm">
        <li className="my-2">
          Use the Application in any manner that could disable, overburden,
          damage, or impair the site or interfere with any other party&#39;s use
          of the Application, including their ability to engage in real time
          activities through the Application.
        </li>
        <li className="my-2">
          Use any robot, spider, or other automatic device, process, or means to
          access the Application for any purpose, including monitoring or
          copying any of the material on the Application.
        </li>
        <li className="my-2">
          Use any manual process to monitor or copy any of the material on the
          Application, or for any other purpose not expressly authorized in
          these terms, without our prior written consent.
        </li>
        <li className="my-2">
          Use any device, software, or routine that interferes with the proper
          working of the Application.
        </li>
        <li className="my-2">
          Introduce any viruses, Trojan horses, worms, logic bombs, or other
          material that is malicious or technologically harmful.
        </li>
        <li className="my-2">
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any parts of the Application, the server on which the
          Application is stored, or any server, computer, or database connected
          to the Application.
        </li>
        <li className="my-2">
          Attack the Application via a denial-of-service attack or a distributed
          denial-of- service attack.
        </li>
        <li className="my-2">
          Otherwise attempt to interfere with the proper working of the
          Application.
        </li>
      </ul>
      <p className="text-lg font-bold mt-4">Privacy and Data :</p>
      <p className="text-sm">
        We respect your privacy and will handle your personal data in accordance
        with our Privacy Policy. By using our application, you consent to the
        collection, use, and storage of your data as described in our Privacy
        Policy.
      </p>
      <p className="text-lg font-bold mt-4">Community Guidelines :</p>
      <p className="text-sm">
        We promote a safe and respectful community. Do not engage in any
        behavior that is abusive, offensive, or harmful to others. We reserve
        the right to remove any content or suspend accounts that violate our
        community guidelines.
      </p>
      <p className="text-lg font-bold mt-4">Intellectual Property :</p>
      <p className="text-sm">
        The application and its entire contents, software, features,
        functionality and all intellectual property rights related thereto are
        owned or licensed by the Company. You must not copy, modify, reverse
        engineer or create derivative works of this application or any software
        therein. You must not delete or alter any copyright, trademark, or other
        proprietary rights notices from this application. The Company name, the
        terms and all related names, logos, product and service names, designs,
        and slogans are trademarks of the Company or its affiliates or
        licensors. You must not use such marks without the prior written
        permission of the Company.
      </p>
      <p className="text-sm mt-2">
        You or your licensors retain ownership of the content, such as any text,
        photographs, user videos and sound recordings, you create using our
        application. By using our application, you or the owner of the content
        grant us a non-exclusive, worldwide, royalty-free license to view,
        access, use, download, modify, adapt, reproduce, make derivative works
        of, publish and/or transmit your content for the purposes of operating
        and promoting our application. All content is considered non-proprietary
        and not confidential. You further grant us a royalty-free license to use
        your user name, image, voice, and likeness to identify you as the source
        of any of your content; provided, however, that your ability to provide
        an image, voice, and likeness may be subject to limitations due to age
        restrictions.
      </p>
      <p className="text-lg font-bold mt-4">Disclaimer of Warranties :</p>
      <p className="text-sm">
        The information presented on or through the Application is made
        available solely for general information purposes. We do not warrant the
        accuracy, completeness, or usefulness of this information. Any reliance
        you place on such information is strictly at your own risk. We disclaim
        all liability and responsibility arising from any reliance placed on
        such materials by you or any other visitor to the Application, or by
        anyone who may be informed of any of its contents.
      </p>
      <p className="text-sm mt-2">
        We do not guarantee that our application will be secure or free from
        bugs or viruses. You are responsible for configuring your information
        technology, computer programmes and platform to access our application.
        You should use your own virus protection software.
      </p>
      <p className="text-sm mt-2">
        YOUR USE OF THE APPLICATION, ITS CONTENT, AND ANY SERVICES OR ITEMS
        OBTAINED THROUGH THE APPLICATION IS AT YOUR OWN RISK. THE APPLICATION,
        ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATION
        ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
        NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
        REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
        QUALITY, ACCURACY, OR AVAILABILITY OF THE APPLICATION. WITHOUT LIMITING
        THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE
        COMPANY REPRESENTS OR WARRANTS THAT THE APPLICATION, ITS CONTENT, OR ANY
        SERVICES, ITEMS, INFORMATION OR CONTENT OBTAINED THROUGH THE APPLICATION
        WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
        WILL BE CORRECTED, OR THAT THE APPLICATION OR ANY SERVICES, ITEMS,
        INFORMATION OR CONTENT OBTAINED THROUGH THE APPLICATION WILL OTHERWISE
        MEET YOUR NEEDS OR EXPECTATIONS.
      </p>
      <p className="text-sm mt-2">
        TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
        OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
        MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
      </p>
      <p className="text-lg font-bold mt-4">Liability :</p>
      <p className="text-sm">
        We strive to provide a reliable and secure application, but we cannot
        guarantee uninterrupted or error-free service. TO THE FULLEST EXTENT
        PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR
        LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS
        BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT
        OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APPLICATION,
        ANY CONTENT OR INFORMATION ON THE APPLICATION, INCLUDING ANY DIRECT,
        INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
        INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
        EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
        OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
        WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR
        OTHERWISE, EVEN IF FORESEEABLE.
      </p>
      <p className="text-lg font-bold mt-4">Indemnification :</p>
      <p className="text-sm">
        You agree to defend, indemnify, and hold harmless the Company, its
        affiliates, licensors, and service providers, and its and their
        respective officers, directors, employees, contractors, agents,
        licensors, suppliers, successors, and assigns from and against any
        claims, liabilities, damages, judgments, awards, losses, costs,
        expenses, or fees (including reasonable attorneys&#39; fees) arising out
        of or relating to your violation of these terms or your use of the
        Application, including, but not limited to, your content, or your use of
        any information obtained from the Application.
      </p>
      <p className="text-lg font-bold mt-4">Changes to Terms :</p>
      <p className="text-sm">
        We reserve the right to update or modify these terms at any time without
        prior notice. It is your responsibility to review these terms
        periodically. All changes are effective immediately when we post them.
        Your continued use of our application after any changes indicates your
        acceptance of the updated terms. You are expected to check this page so
        you are aware of any changes, as they are binding on you.
      </p>
      <p className="text-lg font-bold mt-4">Termination :</p>
      <p className="text-sm">
        We reserve the right to terminate or suspend your access to our
        application at any time, without prior notice or liability, for any
        reason, including but not limited to violation of these terms.
      </p>
      <p className="text-lg font-bold mt-4">
        Governing Law and Dispute Resolution :
      </p>
      <p className="text-sm">
        These terms shall be governed by and construed in accordance with the
        laws of Pennsylvania. Any and all disputes arising from these terms
        shall be resolved final and binding arbitration under the Rules of
        Arbitration of the American Arbitration Association applying
        Pennsylvania law.
      </p>
      <p className="text-lg font-bold mt-4">Entire Agreement :</p>
      <p className="text-sm">
        The terms and our Privacy Policy constitute the sole and entire
        agreement between you and the Company regarding the Application and
        supersede all prior and contemporaneous understandings, agreements,
        representations, and warranties, both written and oral, regarding the
        Application.
      </p>
      <p className="text-sm mt-4">
        By using our Mysynr Application, you agree to these terms and
        conditions. If you have any questions or concerns, please contact us at{" "}
        <a href="mailto:gerald@mysynr.com" className="text-blue-500">
          gerald@mysynr.com
        </a>{" "}
        . Thank you for choosing our application!
      </p>
      <div className="flex flex-col md:flex-row justify-around items-center mt-8">
        <QuickLinks />
        {/* Contact Us Section */}
        <div className="py-8">
          <div className="container mx-auto px-4 flex flex-col md:flex-row justify-around items-center gap-6">
            <h2 className="text-2xl font-bold text-white  text-center mt-8 md:mt-0">
              Contact Us
            </h2>
            <div className="flex justify-center items-center">
              <p
                onClick={() => navigate("/email-us")}
                className="text-xl cursor-pointer text-gray-300 underline hover:text-white"
              >
                Email Us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
