import React, { useEffect } from "react";
import QuickLinks from "./QuickLinks";
import { useNavigate } from "react-router-dom";
import Logo from "../src/assets/logo.png";

const DataDeletion = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="text-white p-8">
      <div
        className="  w-[100%] md:w-[100%] flex justify-start items-end gap-2 cursor-pointer mb-8"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={Logo} className="w-[50px] h-[50px] rounded-lg" alt="logo" />
        <h1 className="text-3xl font-bold text-white text-left ">
          MYSYNR.COM LLC
        </h1>
      </div>
      <p className="text-lg font-bold mb-4">Data Deletion Policy</p>
      <p>
        As part of our commitment to protecting your privacy and ensuring the
        security of your personal information, we provide this Data Deletion
        Policy to outline how we handle the deletion of your data when you use
        our Mysynr application.
      </p>
      <p className="text-lg font-bold mt-4">User Control over Data :</p>
      <p className="text-sm">
        You have the right to request the deletion of your personal data from
        our application. Upon receiving a valid deletion request, we will
        promptly and securely delete your data from our systems, except as
        otherwise provided in this policy.
      </p>

      <p className="text-lg font-bold mt-4">Deletion Process :</p>
      <p className="text-sm">
        To request the deletion of your data, please contact us at{" "}
        <a href="mailto:gerald@mysynr.com" className="text-blue-500">
          gerald@mysynr.com
        </a>{" "}
        from the email address associated with your Mysynr account. In your
        request, please provide sufficient information to verify your identity
        and specify the data you wish to be deleted.
      </p>
      <p className="text-lg font-bold mt-4">Retention Period :</p>
      <p className="text-sm">
        We will retain your personal data only for as long as necessary to
        fulfill the purposes outlined in our Privacy Policy unless a longer
        retention period is required or permitted by law. Upon the expiration of
        the retention period, we will securely delete or anonymize your data.
      </p>
      <p className="text-lg font-bold mt-4">Exceptions to Deletion :</p>
      <p className="text-sm">
        Please note that certain data may be exempt from deletion to the extent
        permitted by applicable law. For example, we may retain data necessary
        to comply with our legal obligations, resolve disputes, enforce our
        agreements, or for legitimate business purposes.
      </p>
      <p className="text-lg font-bold mt-4">Data Security :</p>
      <p className="text-sm">
        We take reasonable measures to protect your personal data against
        unauthorized access, alteration, disclosure, or destruction. When we
        delete your data, we do so in a manner designed to prevent its
        restoration or access by unauthorized parties.
      </p>
      <p className="text-lg font-bold mt-4">Children's Data :</p>
      <p className="text-sm">
        If you are a parent or guardian and believe that we have inadvertently
        collected personal information from a child under 18 without proper
        consent, please contact us immediately, and we will take steps to delete
        such information as soon as possible.
      </p>
      <p className="text-lg font-bold mt-4">
        Changes to Data Deletion Policy :
      </p>
      <p className="text-sm">
        We reserve the right to update or modify this Data Deletion Policy at
        any time without prior notice. Any changes will be effective immediately
        upon posting the updated policy on our website.
      </p>
      <p className="text-sm mt-4">
        Thank you for trusting us with your personal information.
      </p>
      <div className="flex flex-col md:flex-row justify-around items-center mt-8">
        <QuickLinks />
        {/* Contact Us Section */}
        <div className="py-8">
          <div className="container mx-auto px-4 flex flex-col md:flex-row justify-around items-center gap-6">
            <h2 className="text-2xl font-bold text-white  text-center mt-8 md:mt-0">
              Contact Us
            </h2>
            <div className="flex justify-center items-center">
              <p
                onClick={() => navigate("/email-us")}
                className="text-xl cursor-pointer text-gray-300 underline hover:text-white"
              >
                Email Us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataDeletion;
