import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Profile from "./Profile";
import Post from "./Post";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Social from "./Social";
import DataDeletion from "./DataDeletion";
import ContactUs from "./ContactUs";
import DeleteAccount from "./DeleteAccount";
import Scan from "./Scan";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/post/:id" element={<Post />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/privacy-policies" element={<Privacy />} />
        <Route path="/data-deletion" element={<DataDeletion />} />
        <Route path="/social" element={<Social />} />
        <Route path="/email-us" element={<ContactUs />} />
        <Route path="/scan" element={<Scan />} />
      </Routes>
    </Router>
  );
}

export default App;
