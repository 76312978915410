import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Social = () => {
  const location = useLocation();
  const [code, setCode] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (code) {
      setCode(code);
    }
  }, []);

  return (
    <div>
      <p style={{ color: "#FFF" }} className="mt-4">
        {code && (
          <a
            style={{
              width: "200px",
              height: "60px",
              background: "#4db5ff",
              padding: "10px 20px",
              borderRadius: "10px",
              color: "white",
              cursor: "pointer",
              marginTop: "20px",
            }}
            href={`mysynr://social?code=${code}`}
          >
            Open in App
          </a>
        )}
      </p>
    </div>
  );
};

export default Social;
