import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Logo from "../src/assets/logo.png";

const ContactUs = () => {
  const initialValues = {
    email: "",
    subject: "",
    description: "",
  };
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values, { resetForm }) => {
    try {
      setSubmitting(true);
      // Make API call here
      if (!submitting) {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/email_from_site`,
          values
        );
        console.log(response.data); // Assuming your API returns some data

        // Reset form and show success message
        resetForm();
        toast.success("Mail sent successfully!", {
          theme: "dark",
        });
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to send mail. Please try again later.", {
        theme: "dark",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div
        className="  w-[100%] md:w-[100%] flex justify-start items-end gap-2 cursor-pointer mb-8 mt-8 mx-4"
        onClick={() => {
          window.history.back();
        }}
      >
        <img src={Logo} className="w-[50px] h-[50px] rounded-lg" alt="logo" />
        <h1 className="text-3xl font-bold text-white text-left ">
          MYSYNR.COM LLC
        </h1>
      </div>
      <div className="max-w-lg md:mx-auto mx-6">
        <h1 className="text-2xl font-bold my-4 text-white">Contact Us</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email address")
              .required("Email is required"),
            subject: Yup.string()
              .required("Subject is required")
              .max(100, "Subject must be at most 100 characters"),
            description: Yup.string()
              .required("Description is required")
              .max(1000, "Description must be at most 1000 characters"),
          })}
          onSubmit={onSubmit}
        >
          {({ touched, errors, isValid }) => (
            <Form>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-white font-bold mt-6 mb-2"
                >
                  Email
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className={`border border-gray-300 rounded-md p-2 w-full text-white ${
                    touched.email && errors.email ? "border-red-500" : ""
                  }`}
                  placeholder="Enter email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="subject"
                  className="block text-white font-bold mt-6 mb-2"
                >
                  Subject
                </label>
                <Field
                  type="text"
                  id="subject"
                  name="subject"
                  className={`border border-gray-300 rounded-md p-2 w-full text-white ${
                    touched.subject && errors.subject ? "border-red-500" : ""
                  }`}
                  placeholder="Enter subject"
                />
                <ErrorMessage
                  name="subject"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="description"
                  className="block text-white font-bold mt-6 mb-2"
                >
                  Description
                </label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  className={`border border-gray-300 rounded-md p-2 w-full text-white min-h-[300px] ${
                    touched.description && errors.description
                      ? "border-red-500"
                      : ""
                  }`}
                  placeholder="Enter description"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <button
                type="submit"
                className={`bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 ${
                  !isValid || submitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!isValid || submitting}
              >
                {submitting ? (
                  <div className="w-6 h-6 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin"></div>
                ) : (
                  "Submit"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactUs;
