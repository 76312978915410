import React from "react";
import Home from "../src/assets/home.png";
import Sparkels from "../src/assets/sparkles.png";
import SampleVideo from "../src/assets/introV.mp4";

const Welcome = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center gap-20 items-center md:px-8">
      <div className="flex justify-center items-center">
        <video
          src={SampleVideo}
          className="w-[100%] h-[600px]"
          autoPlay
          loop
          muted
          controls
          alt="video"
        />
      </div>
      <div className="p-8">
        <h1 className="text-3xl font-semibold  text-white  mb-8  text-left">
          Welcome to Mysynr
        </h1>
        <div className="flex justify-start items-center gap-4">
          <img src={Sparkels} className="w-[40px] h-[40px]" alt="dot" />
          <p className="text-xl  text-white my-4  text-left">
            Syn and Dance your Life Away!
          </p>
        </div>
        <div className="flex justify-start items-center gap-4">
          <img src={Sparkels} className="w-[40px] h-[40px]" alt="dot" />
          <p className="text-xl  text-white my-6   text-left">
            Let’s create a video on your favorite audio file & Rock your own
            video
          </p>
        </div>
        <div className="flex justify-start items-center gap-4">
          <img src={Sparkels} className="w-[40px] h-[40px]" alt="dot" />
          <p className="text-xl  text-white my-6   text-left">
            Use Lip Syncing, Props, Body magic to create magic while recording
            video
          </p>
        </div>
        <div className="flex justify-start items-center gap-4">
          <img src={Sparkels} className="w-[40px] h-[40px]" alt="dot" />
          <p className="text-xl  text-white my-6   text-left">
            Share with your friends and Create memories together
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
