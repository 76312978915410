import React from "react";
import Logo from "../src/assets/logo512.png";
import Play from "../src/assets/google-play.png";
import Apple from "../src/assets/app-store.png";

const Scan = () => {
  const handlePlayStoreClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.mysynr",
      "_blank"
    );
  };

  const handleAppStoreClick = () => {
    window.open("https://apps.apple.com/app/mysynr/id6550901459", "_blank");
  };

  return (
    <div className="text-white ">
      <h3 className="text-2xl  text-white mt-8 mb-8 text-center">
        Get <span className="font-bold">Mysynr</span> App​
      </h3>
      <p className="text-xl  text-white mt-8 mb-8  text-center">
        Gerald Dragonetti
      </p>
      <div className="w-[250px] h-[250px] m-auto mt-12 mb-12 rounded-xl">
        <img
          className="w-[250px] h-[250px] rounded-xl"
          src={Logo}
          alt="qr code"
        />
      </div>
      <p className="text-2xl font-bold text-white mt-8 mb-8  text-center">
        Syn and Dance your Life Away!
      </p>
      <p className="text-xltext-white mt-8 mb-8  text-center">
        Let’s create a video on your favorite audio file & Rock your own video
        and Use Lip Syncing, Props, Body magic to create magic while recording
        video.
      </p>
      <p className="text-2xl  text-gray-300 mt-8 mb-8  text-center"></p>
      <div className="flex justify-center items-center gap-8 flex-col md:flex-row mb-8">
        <div
          onClick={handlePlayStoreClick}
          className="bg-white p-4 cursor-pointer border-white rounded-2xl flex justify-center items-center gap-8"
        >
          <img
            src={Play}
            className="w-[60px] h-[60px] bg-white"
            alt="google play"
          />
          <div className="bg-white">
            <p className="text-1xl  text-black bg-white">Get it on</p>
            <p className="text-2xl font-semibold  text-black bg-white ">
              Google Play
            </p>
          </div>
        </div>
        <div
          onClick={handleAppStoreClick}
          className="bg-white p-4 cursor-pointer border-white rounded-2xl flex justify-center items-center gap-8"
        >
          <img
            src={Apple}
            className="w-[60px] h-[60px] bg-white"
            alt="apple store"
          />
          <div className="bg-white">
            <p className="text-1xl  text-black bg-white">Download on the</p>
            <p className="text-2xl font-semibold  text-black bg-white ">
              Apple Store
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scan;
