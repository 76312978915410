import React from "react";
import { useNavigate } from "react-router-dom";

const QuickLinks = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col md:flex-row justify-around items-center gap-6">
      <h4 className="text-2xl font-bold text-white mt-8 md:mb-8  text-center">
        Quick Links
      </h4>
      <p
        className="text-xl cursor-pointer text-gray-300 underline hover:text-white"
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </p>
      <p
        className="text-xl cursor-pointer text-gray-300 underline hover:text-white"
        onClick={() => {
          navigate("/terms-conditions");
        }}
      >
        Terms and Conditions
      </p>
      <p
        className="text-xl cursor-pointer text-gray-300 underline hover:text-white"
        onClick={() => {
          navigate("/privacy-policies");
        }}
      >
        Privacy and Policy
      </p>
      <p
        className="text-xl cursor-pointer text-gray-300 underline hover:text-white"
        onClick={() => {
          navigate("/data-deletion");
        }}
      >
        Data Deletion Policy
      </p>
    </div>
  );
};

export default QuickLinks;
