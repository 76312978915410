import React, { useState } from "react";
import { useParams } from "react-router-dom";

function Post() {
  const { id } = useParams();

  const openAppOrStore = () => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /android/i.test(navigator.userAgent);

    const appLink = `mysynr://post/${id}`;
    const playStoreLink =
      "https://play.google.com/store/apps/details?id=com.mysynr"; // Replace with your Play Store link
    const appStoreLink = "https://apps.apple.com/us/app/mysynr/id6550901459"; // Replace with your App Store link

    // Try opening the app
    window.location.href = appLink;

    // If the app does not open, after a delay, redirect to the app store
    setTimeout(() => {
      if (isIOS) {
        window.location.href = appStoreLink;
      } else if (isAndroid) {
        window.location.href = playStoreLink;
      }
    }, 2000); // 2-second delay to allow the app to open before redirecting
  };

  return (
    <div className="p-8">
      <p style={{ color: "#FFF" }}>Open this page in mysynr app</p>
      <p style={{ color: "#FFF" }} className="mt-4">
        <button
          style={{
            width: "200px",
            height: "60px",
            background: "#4db5ff",
            padding: "10px 20px",
            borderRadius: "10px",
            color: "white",
            cursor: "pointer",
            marginTop: "20px",
          }}
          onClick={openAppOrStore}
        >
          Open in App
        </button>
      </p>
    </div>
  );
}

export default Post;
